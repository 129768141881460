
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import { findNearestTimelineIndex } from "@evercam/shared/utils"
import { mapStores } from "pinia"
import { useBimCompareStore } from "@evercam/dashboard/stores/bimCompare"
import { useSnapshotStore } from "@evercam/dashboard/stores/snapshots"
import { useRecordingsStore } from "@evercam/dashboard/stores/recordings"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types"
import BimCompareExportDialog from "@/components/bimCompare/BimCompareExportDialog"

export default {
  name: "BimCompareSidebar",
  components: {
    DateTimePicker,
    BimCompareExportDialog,
  },
  data() {
    return {
      isLoadingDays: false,
      isLoadingHours: false,
      tab: this.firstTab,
      bimAvailableDays: [],
      bimAvailableHours: [],
    }
  },
  computed: {
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    ...mapStores(
      useBimCompareStore,
      useSnapshotStore,
      useRecordingsStore,
      useCameraStore,
      useAccountStore
    ),
    isBIMTimelineAvailable(): boolean {
      if (!this.bimCompareStore.model) {
        return true
      } else {
        return this.bimAvailableHours.length > 0
      }
    },
    firstTab(): number {
      return this.isBIMTimelineAvailable ? 0 : 1
    },
    maxDate(): string {
      return this.$moment().toISOString()
    },
    minDate(): string {
      return this.$moment(
        Math.min(
          this.$moment(
            this.snapshotStore.oldestSnapshotTimestamp(
              this.cameraStore.selectedCameraExid
            )
          ),
          this.$moment(this.cameraStore.selectedCamera?.createdAt)
        )
      ).toISOString()
    },
    isLandscapeMode() {
      return window.innerWidth > window.innerHeight
    },
    selectedBimDate() {
      if (!this.bimCompareStore.bimDatetime) {
        return
      }

      return this.$moment
        .tz(this.bimCompareStore.bimDatetime, this.timezone)
        .format("YYYY-MM-DD")
    },
  },
  watch: {
    "bimCompareStore.bimDatetime"(timestamp) {
      if (!timestamp) {
        return
      }
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectBimDate, {
        timestamp,
      })
    },
    "recordingsStore.selectedTimestamp": {
      handler(timestamp) {
        if (!timestamp) {
          return
        }
        const selectedTimestamp = this.$moment.tz(timestamp, this.timezone),
          selectedDay = selectedTimestamp.format("YYYY-MM-DD"),
          selectedMonth = selectedTimestamp.format("YYYY-MM")
        this.bimCompareStore.availableDays =
          this.recordingsStore.availableDaysByMonth[selectedMonth] || []
        this.bimCompareStore.availableHours =
          this.recordingsStore.availableHoursByDay[selectedDay] || []
      },
      immediate: true,
    },
    "bimCompareStore.fullDates": {
      handler() {
        if (!this.bimCompareStore.bimDatetime) {
          return
        }
        const month = this.$moment(this.bimCompareStore.bimDatetime).format(
          "YYYY-MM"
        )
        this.getBimAvailableDays(month)
        this.getBimAvailableHours()
      },
      immediate: true,
    },
  },
  methods: {
    getBimAvailableDays(month) {
      this.bimAvailableDays = this.bimCompareStore.bimDailyEvents.reduce(
        (acc, date) => {
          if (this.$moment(date).format("YYYY-MM") === month) {
            return [
              ...acc,
              Number.parseInt(this.$moment.tz(date, this.timezone).format("D")),
            ]
          }

          return acc
        },
        []
      )
    },
    getBimAvailableHours() {
      this.bimAvailableHours = this.bimCompareStore.fullDates.reduce(
        (acc, date) =>
          this.$moment(date).format("YYYY-MM-DD") === this.selectedBimDate
            ? [...acc, Number.parseInt(this.$moment(date).format("HH"))]
            : acc,
        []
      )
    },
    async onMonthChange(month) {
      if (!month || this.isLoadingDays) {
        this.bimCompareStore.availableDays = []

        return
      }

      this.isLoadingDays = true
      await this.recordingsStore.fetchAvailableDaysForMonth(
        month,
        this.cameraStore.selectedCameraExid
      )
      this.bimCompareStore.availableDays =
        this.recordingsStore.availableDaysByMonth[month] || []
      this.isLoadingDays = false
    },
    async onDayChange(date) {
      if (!date || this.isLoadingHours) {
        this.bimCompareStore.availableHours = []

        return
      }

      this.isLoadingHours = true
      await this.recordingsStore.fetchAvailableHoursForDay(
        date,
        this.cameraStore.selectedCameraExid
      )
      const selectedDay = this.$moment
        .tz(date, this.timezone)
        .format("YYYY-MM-DD")
      this.bimCompareStore.availableHours =
        this.recordingsStore.availableHoursByDay[selectedDay] || []
      this.isLoadingHours = false
    },
    onDateChange(value, isGetNearestBimImage) {
      if (isGetNearestBimImage) {
        this.getNearestBimImage()
      }
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectCameraDate, {
        selectedTimestamp: value,
      })
    },
    getNearestBimImage() {
      if (!this.bimCompareStore.bimDatetime || this.bimCompareStore.finalView) {
        return
      }
      this.getBimAvailableHours()
      const timestamp = this.$moment
        .tz(this.bimCompareStore.bimDatetime, this.timezone)
        .format("YYYY-MM-DDTHH:mm:ss")
      this.bimCompareStore.bimIndex = findNearestTimelineIndex(
        timestamp,
        this.bimCompareStore.bimAvailableDays
      )
      this.$emit("load-bim-image")
    },
    selectOldestSnapshot() {
      this.recordingsStore.selectedTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        )
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectCameraOldestDate)
    },
    selectLatestSnapshot() {
      this.recordingsStore.selectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp(
          this.cameraStore.selectedCameraExid
        )
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectCameraLatestDate)
    },
    triggerChangeTransparencyEvent() {
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareChangeTransparency, {
        transparencyLevel: this.bimCompareStore.transparency,
      })
    },
    selectLastFrame() {
      this.bimCompareStore.bimDatetime = this.bimCompareStore.bimMaxDate
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectBimLatestDate)
    },
    selectFirstFrame() {
      this.bimCompareStore.bimDatetime = this.bimCompareStore.bimMinDate
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectBimOldestDate)
    },
    loadBimModel() {
      this.$emit("load-bim-model")
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareSelectModel, {
        model: this.bimCompareStore.model,
      })
    },
  },
}
