
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import BimCompareViewer from "@/components/bimCompare/BimCompareViewer"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import BimCompareSidebar from "@/components/bimCompare/BimCompareSidebar"
import { mapStores } from "pinia"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useSnapshotStore } from "@/stores/snapshots"
import { useRecordingsStore } from "@/stores/recordings"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"

export default {
  components: {
    BimCompareViewer,
    BimCompareSidebar,
    EvercamLoadingAnimation,
    RightSidebarContent,
  },
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
    selectedCamera: {
      type: Object,
      default: () => {},
    },
    isTimelinePage: {
      type: Boolean,
      default: false,
    },
    isBottomBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reloadBim: Math.round(Math.random() * 1000),
      isInitialized: false,
    }
  },
  async fetch() {
    await this.snapshotStore.updateLatestAndOldestSnapshots(
      this.selectedCamera.id
    )
    if (!this.isTimelinePage) {
      this.recordingsStore.selectedTimestamp = this.selectedCamera.isOnline
        ? this.snapshotStore.latestSnapshotTimestamp(this.selectedCamera.id)
        : this.selectedCamera.lastOnlineAt
    }
    this.isInitialized = true
  },
  computed: {
    ...mapStores(
      useBimCompareStore,
      useSnapshotStore,
      useRecordingsStore,
      useAccountStore,
      useLayoutStore
    ),
    isBottombar() {
      return this.isBottomBar || this.$route.query.bottombar === "true"
    },
    canShowRightSidebar() {
      return (
        !this.isTimelinePage &&
        (this.isSidebarEnabledFromQueryParam ||
          this.bimCompareStore.isSidebarPreview)
      )
    },
    isSidebarEnabledFromQueryParam() {
      return this.$route.query.sidebar === "true"
    },
  },
  watch: {
    "recordingsStore.selectedTimestamp": {
      immediate: true,
      handler(timestamp) {
        if (!timestamp) {
          return
        }
        const selectedTimestamp = this.$moment.tz(timestamp, this.timezone),
          selectedDay = selectedTimestamp.format("YYYY-MM-DD"),
          selectedMonth = selectedTimestamp.format("YYYY-MM")
        this.bimCompareStore.availableDays =
          this.recordingsStore.availableDaysByMonth[selectedMonth] || []
        this.bimCompareStore.availableHours =
          this.recordingsStore.availableHoursByDay[selectedDay] || []
        this.$refs.comparer?.onCalendarChange(timestamp)

        if (this.isEmbedded) {
          this.switchMode()
        }
      },
    },
    canShowRightSidebar: {
      handler(value) {
        if (this.bimCompareStore.isWidgetPreview) {
          return
        }

        if (value) {
          this.layoutStore.enableRightSidebar()
        } else {
          this.layoutStore.disableRightSidebar()
        }
      },
      immediate: true,
    },
    "bimCompareStore.isSidebarPreview"() {
      this.reloadBim = Math.round(Math.random() * 1000)
    },
    "bimCompareStore.isBottombarPreview"() {
      this.reloadBim = Math.round(Math.random() * 1000)
    },
  },
  mounted() {
    if (this.isTimelinePage) {
      this.$root.$on("load-bim-etimeline-data", () => {
        this.$refs.comparer.renderBimData()
        this.$refs.comparer.renderBimImage()
      })
    }
  },
  beforeDestroy() {
    if (this.isEmbedded) {
      this.bimCompareStore.$reset()
    }
    if (!this.isTimelinePage) {
      this.layoutStore.disableRightSidebar()
    }
  },
  methods: {
    switchMode() {
      this.$theme.switchTheme({
        isDark: this.isDarkMode,
        disablePersistToLocalStorage: this.isEmbedded,
      })
    },
    handleResize() {
      this.$refs.comparer?.handleResize()
    },
  },
}
